"use client";

import { useEffect, useState } from "react";
import Card, { CardBody } from "@/components/ui/Card";
import Icon from "@/components/icon/Icon";
import parcelGray  from "@/assets/icon/pracelgray.svg"
import { getParcels } from "@/actions";
import Link from "next/link";
import Image from "next/image";
import { appPages } from "@/config/pages.config";
import { TParcel } from "@/types/parcel.type";

const MyParcels = () => {
  const [stats, setStats] = useState<TParcel[]>([{
    id: "",
    warehouse_id: "",
    account_id: "",
    weight_grams: "",
    width_cms: "",
    height_cms: "",
    length_cms: "",
    status: "",
    items: [],
    image_urls: [],
    images: [],
    arrived_at: "",
    declared_value: "",
    date: ""
  }]);

  useEffect(() => {
    const fetchAccountStats = async () => {
      try {
        const parcels: TParcel[] = await getParcels();
        
        if (!Array.isArray(parcels)) {
          setStats([]);
          return;
        }
  
        if (parcels.length === 0) {
          setStats([]);
          return;
        }
  
        setStats(parcels.filter(parcel => parcel.status === 'arrived'));
      } catch (error) {
        console.error('Error fetching parcels:', error);
        setStats([]); // Optionally handle error state
      }
    };
  
    fetchAccountStats();
  }, []);

  const isDisabled = stats.length === 0;
  console.log("🚀 ~ MyParcels ~ stats:", stats)
  return (
    <Card
      style={{ height: "11em" }}
      className={isDisabled ? "bg-gray-200" : ""}
    >
      <CardBody className="flex flex-col justify-end">
        {isDisabled ? (
          <div className="flex flex-col items-center text-gray-400 cursor-not-allowed">
            <Image src={parcelGray} alt="Parcel Icon" />
            <div className="flex items-center justify-center rounded-full bg-gray-400 h-8 w-8 text-white my-3">
              {stats.length}
            </div>
            <span>My Parcel</span>
          </div>
        ) : (
          <Link href={appPages.parcelsPage.to}>
            <div className="flex flex-col items-center">
              <Icon icon="ParcelIcon" size="text-6xl" />
              <div className="flex items-center justify-center rounded-full bg-green-500 h-8 w-8 text-white my-3">
                {stats.length}
              </div>
              <span>My Parcel</span>
            </div>
          </Link>
        )}
      </CardBody>
    </Card>
  );
};

export default MyParcels;
