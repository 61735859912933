"use client";
import X from "@/assets/icon/x.svg";
import { FC, ReactNode } from "react";
import Image from "next/image";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
}

const Modal: FC<ModalProps> = ({ open, onClose, children, title }) => {
    
  return (
    // backdrop
    <div
      onClick={onClose}
      className={`
        fixed inset-0 flex justify-center items-center transition-colors
        ${open ? "visible bg-black/20" : "invisible"}
      `}
    >
      {/* modal */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`
          relative bg-white rounded-xl shadow-lg p-6 transition-all
          ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
          w-full max-w-3xl // Updated width for larger screens
        `}
      >
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-gray-900 text-center w-full">
            {title}
          </h2>
          <button
            onClick={onClose}
            className="p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
          >
            <Image src={X} alt="close" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;