import Link from "next/link";
import Icon from "./icon/Icon";
import Card, { CardBody } from "./ui/Card";
import { getAccountStats } from "@/actions";
import { appPages } from "@/config/pages.config";
import { useEffect, useState } from "react";
import Image from "next/image";
import shipmentGray from "@/assets/icon/ei_plus_gray.svg";
type TAccountStats = {
  total_parcels?: number;
};

const CreateShipment = () => {
  const [stats, setStats] = useState<TAccountStats>({ total_parcels: 0 });

  useEffect(() => {
    const fetchAccountStats = async () => {
      const data = await getAccountStats();
      setStats(data);
    };

    fetchAccountStats();
  }, []);
  const isDisabled = stats.total_parcels === 0;
  return (
    <Card
      style={{ height: "11em" }}
      className={isDisabled ? "bg-gray-200" : ""}
    >
      <CardBody className="flex flex-col justify-end">
        {isDisabled ? (
          <>
            <div className="flex flex-col items-center text-gray-400 cursor-not-allowed">
              <Image src={shipmentGray} alt="Shipment Icon" />
              <span>Create Shipment</span>
            </div>
          </>
        ) : (
          <>
            <Link href={appPages.shipmentPage.subPages.createShipmentPage.to}>
              <div className="flex flex-col items-center">
                <Icon
                  icon="CreateShipmentIcon"
                  style={{ fontSize: "7.5rem" }}
                />
                <span>Create Shipment</span>
              </div>
            </Link>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default CreateShipment;
