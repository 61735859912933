"use client";

import { useLiff } from "@/context/liff";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Greeting from "@/components/Greeting";
import TranslationsProvider from "@/components/TranslationsProvider";
import MyParcels from "@/components/MyParcels";
import CreateShipment from "@/components/CreateShipment";
import PageFallbackTemplate from "@/templatestable/PageFallback.template";


const i18nNamespaces = ["translation"];

export default function Home({
  params: { locale },
}: {
  params: { locale: string };
}) {
  const { liffObject, liffError } = useLiff();

  if (liffError) {
    return <div>Error: {liffError}</div>;
  }
  if (!liffObject) {
    return <div>loading...</div>;
  }

  return (
    <TranslationsProvider
      namespaces={i18nNamespaces}
      locale={locale}
      fallback={<PageFallbackTemplate />}
    >
      <PageWrapper>
        <Greeting />
        <div className='container mx-auto h-full p-6'>
          <div className="grid grid-cols-12 gap-4">
            <div className='col-span-6 text-center'>
              <MyParcels />
            </div>
            <div className='col-span-6 text-center'>
              <CreateShipment />
            </div>
            <div className='col-span-12'>
            </div>
          </div>
        </div>
      </PageWrapper>
    </TranslationsProvider>
  );
}
