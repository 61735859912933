"use client";

import { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import classNames from "classnames";
import Button from "@/components/ui/Button";
import { TAccount } from "@/types/account.type";
import { getAccount } from "@/actions/accounts";
import busIcon from "@/assets/icon/busIcon.svg";
import Image from "next/image";
import Modal from "@/components/Modal";
const Greeting = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const hasSeenModal = localStorage.getItem("hasSeenModal");

    // Check if the user has already seen the modal
    if (!hasSeenModal) {
      setOpen(true);
      localStorage.setItem("hasSeenModal", "true"); // Set a flag in localStorage
    }
  }, []);
  const { data: session } = useSession();
  const firstname = session?.user?.firstname || "";
  const lastname = session?.user?.lastname || "";
  const name = `${firstname} ${lastname}`;
  const greetingMessage = name ? (
    <>
      Hi! <br />
      {name}
    </>
  ) : (
    "Hi!"
  );

  const [account, setAccount] = useState<TAccount | null>(null);
  const [addressLocale, setAddressLocale] = useState<string>("TH"); // ['TH', 'EN']

  useEffect(() => {
    const fetchAccount = async () => {
      if (session?.user?.id) {
        // fetch account info, which should have warehouse address
        const account = await getAccount(session.user.id);
        setAccount(account);
        console.log(account);
      }
    };

    fetchAccount();
  }, [session]);

  const handleSwitchLanguage = (lang: string) => {
    setAddressLocale(lang);
  };

  const handleCopyAddress = () => {
    const accountNameString = getAccountNameString();
    const addressString = getAddressString(addressLocale);
    const phoneString = getPhoneString(addressLocale);
    if (addressString) {
      navigator.clipboard.writeText(
        `${accountNameString} ${addressString} ${phoneString}`
      );
    }
  };

  const renderSwitchLanguageButton = (lang: string) => {
    const buttonClass = classNames(
      lang === "TH" ? "pl-0" : "",
      addressLocale === lang ? "font-bold opacity-100" : "opacity-50"
    );
    return (
      <Button
        variant="default"
        className={buttonClass}
        onClick={() => {
          handleSwitchLanguage(lang);
        }}
        isDisable={addressLocale === lang}
      >
        <span>{lang}</span>
      </Button>
    );
  };

  const getAddressString = (locale: string) => {
    let address;
    if (account && account.addresses) {
      address = account.addresses.find(
        (address) => address.language_code === locale.toLowerCase()
      );
      return `${address?.address_line1}, ${address?.address_line2}, ${address?.city}, ${address?.state}, ${address?.country}, ${address?.postal_code}`;
    }
  };

  const getPhoneString = (locale: string) => {
    if (account) {
      const address = account.addresses.find(
        (address) => address.language_code === locale.toLowerCase()
      );
      return `${locale.toLowerCase() == "en" ? "Tel:" : "โทร:"} ${
        address?.phone
      }`;
    }
  };

  const getAccountNameString = () => {
    return `${name} (FB ${session?.user?.id})`;
  };

  const renderAddress = (locale: string) => {
    if (!account) return;

    return (
      <>
        {getAddressString(locale)} <br />
        {getPhoneString(locale)}
      </>
    );
  };

  return (
    <>
      <div
        className="flex flex-col p-6 items-start justify-start"
        style={{ backgroundColor: "#38BA9C73" }}
      >
        <div className="font-medium text-2xl mb-10">{greetingMessage}</div>
        <div className="flex items-center mb-2">
          <span className="mr-2 text-gray-500">
            Sending your parcels to our warehouse
          </span>
          <Image src={busIcon} alt="Bus" width={15} height={15} />
        </div>
        <div className="w-full rounded-lg mx-auto bg-white p-6">
          <span className="font-semibold">{getAccountNameString()}</span>
          <div className="my-2 text-gray-500">
            <span>{addressLocale === "TH" ? "ที่อยู่ : " : "Address : "}</span>
            <span>{renderAddress(addressLocale)}</span>
          </div>
          <div className="flex justify-between">
            <div>
              {renderSwitchLanguageButton("TH")}|
              {renderSwitchLanguageButton("EN")}
            </div>
            <div>
              <Button
                variant="solid"
                icon="HeroDocumentDuplicate"
                onClick={handleCopyAddress}
              >
                Copy Address
              </Button>
            </div>
          </div>
        </div>
        <span
          onClick={() => setOpen(true)}
          className="block mt-4 text-center w-full underline text-blue-600"
        >
          How to use?
        </span>
      </div>
      <Modal title="How to use" open={open} onClose={() => setOpen(false)}>
        <div className="w-full h-96 flex justify-center items-center bg-gray-100 rounded-lg">
          {/* YouTube Video Embed */}
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/Fu5vou7jUu0"
            // https://www.youtube.com/watch?v=Fu5vou7jUu0
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </>
  );
};

export default Greeting;
